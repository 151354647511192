import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Skeleton,
  useTheme
} from '@mui/material';
import ButtonIcon from 'components/ButtonIcon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getRevisionResult } from 'services/revisions';
import { getRevisionErrorHtmlContent } from 'utils/utils';

const RevisionError = ({
  revisionID,
  projectID,
  businessID,
  open,
  setShowRevisionError,
}: {
  revisionID: string;
  projectID: string;
  businessID: string | undefined;
  open: boolean;
  setShowRevisionError: any;
}) => {
  const [_, setOpen] = React.useState(true);
  const theme = useTheme();
  const [errorEneregyPlus, setErrorEneregyPlus] = useState(false)
  const isRTL = theme.direction == 'rtl';
  const { t } = useTranslation();
  const {
    status: resultstatus,
    data: resultdata,
    isLoading: isresultLoading,
  } = useQuery(
    ['GetRevisionResultByIDERROR', revisionID, projectID, businessID],
    async () => await getRevisionResult(revisionID, projectID, businessID)
  );

  const messages = resultdata?.data.error
  const messagesEneregyPlusError = resultdata?.data.error.message.some((message: string) => message.includes("Program"));
  useEffect(() => {
    if (messagesEneregyPlusError) {
      setErrorEneregyPlus(true)
    }
  }, [resultdata, messagesEneregyPlusError, theme.direction])




  return (
    <>
      <Dialog
        open={open}
        onClose={() => setShowRevisionError(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        dir={errorEneregyPlus ? "ltr" : theme.direction}
        maxWidth='lg'
        className='revision-error-dialog'
        sx={{
          '& .MuiDialog-paper': {
            overflowY: 'auto',
            minWidth: "500px",
            maxHeight: '90vh',
          },
        }}
      >
        <DialogTitle sx={{ display: 'flex', py: 2, height: "50px" }} className='dialog-title'>
          <ButtonIcon
            sx={{
              position: 'absolute',
              [errorEneregyPlus && isRTL ? 'left' : 'right']: 8,
              top: 4
            }}
            aria-label='close'
            onClick={() => setShowRevisionError(false)}
          >
            <CloseIcon />
          </ButtonIcon>
        </DialogTitle>
        <DialogContent
          sx={{
            textAlign: 'start',
            py: 0,
          }}
          className='dialog-content'
        >
          <Box sx={{
            display: 'flex', flexDirection: "column", justifyContent: "space-between", fontSize: errorEneregyPlus ? '14px' : 'inherit',
          }}>
            {isresultLoading ? (
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            ) : (
              getRevisionErrorHtmlContent(messages)
            )}
            <Box sx={{ paddingTop: "1rem" }}>
              {t('TRAINING_VIDEOS_1')} {''}
              <Link href='/how-it-works' target='_blank'>
                {t('TRAINING_VIDEOS_2')}
              </Link>
            </Box>
          </Box>

        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: "50px"
          }}
        >
          {/*    <Button
            onClick={() => setShowRevisionError(false)}
            variant='contained'
            autoFocus
          >
            Close
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RevisionError;
