import { CircularProgress, FormControl, Grid, MenuItem, TextField } from '@mui/material';
import ButtonCustom from 'components/ButtonCustom';
import AuthContext from 'context/AuthContext';
import LanguageContext from 'context/LanguageContext';
import { Field, Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { inviteMember, updatePermission } from 'services/business-setting-service';
import { IInviteUser } from 'utils/contracts';
import { user_authorizations } from 'utils/Formatter';
import { getErrorHtmlContent } from 'utils/utils';
import * as Yup from 'yup';

const textFieldStyles = {
  '& .MuiInputBase-root': {
    height: '50px', // Custom height
    padding: '10px 0px', // Adjust padding as needed
  },
  '& .MuiInputLabel-outlined': {
    transform: 'translate(14px, 13px) scale(1)', // Adjust label position
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, -10px) scale(0.75)',
  },
};

const MemberInviteForm = ({ members, refetch }: any) => {
  const { t } = useTranslation();
  const [isLoading, setisLoading] = useState(false);
  const { businessId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(AuthContext);
  const {language} = useContext(LanguageContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('INVALID_EMAIL'))
      .required(t('REQUIRED_FIELD')),
    role: Yup.string()
      .required(t('REQUIRED_FIELD'))
  });

  const initialValues = {
    email: '',
    role: ''
  };

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      const existingUser = members.find(user => user.email === values.email);
      if ( user?.email === values.email) {
        enqueueSnackbar(t('BUSINESS_SETTING_MEMBER_INVITE_ERROR'), { variant: 'error' });
        refetch();
        resetForm();
        return;
      }
      if (existingUser) {
        const response = await updatePermission(existingUser.id, values.role, businessId);
        if (response.status === 200) {
          enqueueSnackbar(t('BUSINESS_EDIT_SETTING_MEMBER_SUCCESS'), { variant: 'success' });
          refetch();
          resetForm();
        }
      } else {
        setisLoading(true);
        const data = await inviteMember({
          email: values.email,
          authorization: values.role,
          organizationId: businessId,
          language: language
        } as IInviteUser);
        if (data.status === 200) {
          enqueueSnackbar(t('BUSINESS_STAFF_INVITE_SUCCESS'), { variant: 'success' });
          refetch();
          resetForm();
        }
      }
    } catch (error: any) {
      enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
      console.log('onError', error);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, values }) => (
        <Form>
          <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignContent: 'center', alignItems:"flex-end" , flexWrap:"wrap"}}>
            <Field name="email">
              {({ field }) => (
                <TextField
                  {...field}
                  variant='outlined'
                  size='small'
                  sx={{...textFieldStyles , width: {sm:'300px', xs:"200px"}, textAlign: 'left'}}
                  label={t('BUSINESS_SETTING_MEMBER_EMAIL')}
                  type='email'
                  dir="ltr"
                  
                  error={touched.email && Boolean(errors.email)}
                  //helperText={touched.email && errors.email}
                  autoComplete='off'
                  autoCorrect='off'
                  required
                />
              )}
            </Field>

            <Field name="role">
              {({ field }) => (
                <FormControl sx={textFieldStyles}>
                  <TextField
                    {...field}
                    select
                    label={t('BUSINESS_SETTING_MEMBER_PERMISSION')}
                    variant='outlined'
                    size='small'
                    error={touched.role && Boolean(errors.role)}
                    //helperText={touched.role && errors.role}
                    sx={{ width:"200px" , height: '50px' }}
                    required
                  >
                    {user_authorizations.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {t(option.label)}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              )}
            </Field>

            <ButtonCustom
              color="primary"
              type="submit"
              variant="contained"
              disabled={isLoading}
              startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {t('SAVE_BUTTON')}
            </ButtonCustom>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default MemberInviteForm;