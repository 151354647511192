import { Box, TextField } from '@mui/material';
import { AxiosResponse } from 'axios';
import ButtonCustom from 'components/ButtonCustom';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from 'utils/AxiosInstance';
import { API_PATHS } from 'utils/constants';
import { IErrorResponse, IForgotRequest } from 'utils/contracts';
import { getErrorHtmlContent } from 'utils/utils';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [emailError, setEmailError] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const mutation = useMutation({
    mutationFn: async (data: IForgotRequest) => {
      const response = await axiosInstance.post<any, AxiosResponse<any>>(
        API_PATHS.NORMAL_USER_FORGOT,
        JSON.stringify({
          email: data.email,
        })
      );
      return response;
    },
    onSuccess(data, variables, context) {
      setSuccess(true);
      setEmail('');
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
      enqueueSnackbar(t('FORGOT_PASSSWORD_MESSAGE_SUCCESS'), {
        variant: 'success',
      });
    },
    onError(error: IErrorResponse) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
      }
    }
  });

  const btnHandler = async (e: any) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError(t('FORGOT_EMAIL_INVALID_ERROR'));
      return;
    }
    setEmailError('');
    
    const data: IForgotRequest = {
      email,
    };
    mutation.mutate(data);
  };

  return (
    <>
   
      <Box className='form-container'>
        <form
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <TextField
            name='email'
            label={t('FORGOT_EMAIL')}
            type='email'
            fullWidth
            variant='standard'
            dir="ltr"
            sx={{ textAlign: 'left' }}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError('');
            }}
            helperText={emailError || t('FORGOT_EMAIL_HELPTEXT')}
            error={!!emailError}
          />

          <Box
            dir={'ltr'}
            sx={{
              marginTop: '3rem',
              gap: "10px",
              display: 'flex',
              flexDirection: { xs: 'column-reverse', sm: 'row' }, 
              justifyContent: { xs: 'center', sm: 'space-between' }, 
              alignItems: { xs: 'center' }, 

              width:"100%"
            }}
          >
            <ButtonCustom
              color='primary'
              type='submit'
              className='secondary-btn'
              variant='contained'
              onClick={(e: any) => {
                e.preventDefault();
                navigate('/login');
              }}
            >
              {t('LOGIN_BUTTON')}
            </ButtonCustom>
            <ButtonCustom
              color='primary'
              type='submit'
              variant='contained'
              onClick={btnHandler}
            >
              {t('FORGOT_BUTTON')}
            </ButtonCustom>

          </Box>
        </form>
      </Box>
    </>
  );
};

export default ForgotPassword;
