import { CircularProgress, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonCustom from 'components/ButtonCustom';
import PasswordTextField from 'components/PasswordTextField';
import UseAuthContext from 'context/AuthContext';
import UseLanguageContext from 'context/LanguageContext';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IUserLoginRequest } from 'utils/contracts';
import { getErrorHtmlContent } from 'utils/utils';

const Login = () => {
  const location = useLocation();
  const { setLanguage } = useContext(UseLanguageContext);
  const [email, setEmail] = useState(location?.state?.email || '');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();
  const { login } = useContext(UseAuthContext);
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (location?.state?.language) {
      setLanguage(location?.state?.language);
    }
  }, [location?.state?.language]);

  const LoginHandler = async (e: any) => {
    try {
      setisLoading(true);
      e.preventDefault();
      const data: IUserLoginRequest = {
        email,
        password,
      };
      await login(data);
      setisLoading(false);
    } catch (error: any) {
      enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
      console.log('onError', error);
      setisLoading(false);
    }
  };

  return (
    <Box >
      <form
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: "45px",
          
        }}
        
      >
        <TextField
          name='email'
          label={t('LOGIN_EMAIL')}
          type='email'
          fullWidth
          variant='standard'
          dir="ltr"
          helperText={t('LOGIN_EMAIL_HELPTEXT')}
          sx={{ 
            textAlign: 'left'
          }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <PasswordTextField
          name='password'
          label={t('LOGIN_PASSWORD')}
          fullWidth
          sx={{ '& .MuiInputBase-formControl': { letterSpacing: "4px", pb: "2px", }, textAlign: 'left' }}
          variant='standard'
          value={password}
          dir="ltr"
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <Box
          dir={'ltr'}
          sx={{
            mt: 2,
            gap: "10px",
            display: 'flex',
            flexWrap: "wrap",
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            alignItems: { xs: 'center' },
            justifyContent: 'space-between',
          }}
        >
          <ButtonCustom
            color='info'
            variant='contained'
            className='secondary-btn'
            onClick={(e: any) => {
              e.preventDefault();
              navigate('/forgot');
            }}
          >
            {t('LOGIN_FORGOT_PASSWORD_BUTTON')}
          </ButtonCustom>
          <ButtonCustom
            color='primary'
            type='submit'
            variant='contained'
            onClick={LoginHandler}
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={20} color='inherit' /> : null}
          >
            {t('LOGIN_BUTTON')}
          </ButtonCustom>

        </Box>
      </form>
    </Box>
  );
};

export default Login;
