import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';

const PasswordTextField = (props: any) => {
  const [showPassword, setShowPassword] = useState(false);
  
  const handleClickShowPassword = () => setShowPassword(!showPassword) ;
console.log(props.value)
  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      autoCorrect='off'
      autoComplete='new-password'
      InputProps={{
        endAdornment: (showPassword || props.value) ? (
          <InputAdornment position='start'
          onClick={handleClickShowPassword}
          >
            <IconButton
              aria-label='toggle password visibility'
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      {...props}
    />
  );
};

export default PasswordTextField;
