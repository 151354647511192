import { Box, TextField, Typography } from '@mui/material';
import ComapnySVG from 'assets/company-reg-bg.svg';
import ButtonCustom from 'components/ButtonCustom';
import NoFlipBox from 'components/NoFlipBox';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { signupOrganisation } from 'services/auth-service';
import { IErrorResponse, IORGRegistrationRequest } from 'utils/contracts';
import { getErrorHtmlContent } from 'utils/utils';





const SignUP = () => {
  const [company_name, setCompanyName] = useState<string>('');
  const [company_address, setAddress] = useState<string>('');
  const [company_email, setEmail] = useState<string>('');
  const [company_phone, setPhone] = useState<string>('');
  const [isLoading, setisLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const SignupHandler = async (e: any) => {
    try {
      setisLoading(true);
      e.preventDefault();
      await signUpMutation.mutateAsync({
        company_name,
        company_address,
        company_email,
        company_phone,
      });
      enqueueSnackbar(t('ORGANISATION_REGISTRATION_SUCCESS_MESSAGE'), {
        variant: 'success',
      });
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
    }
  };

  const signUpMutation = useMutation({
    mutationFn: async (data: IORGRegistrationRequest) => {
      const loginResponse = await signupOrganisation(data);
      return loginResponse;
    },
    onSuccess(data) {
      const responseData = data.data;
      resetForm();
      return responseData;
    },
    onError(error: IErrorResponse, variables, context) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error, variables, context);
      }
    },
    onSettled() {
      setisLoading(false);
    },
  });

  const resetForm = () => {
    setCompanyName('');
    setAddress('');
    setEmail('');
    setPhone('');
  };



  /*   if (isLoading){
      return <Spinner />
    } */
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: 'flex',
          gap: { xs: "0px", md: "50px" },
          flexDirection: { xs: 'column', md: 'row-reverse', lg: 'row-reverse' },
          justifyContent: 'flex-end',
          alignItems: "center",
          mt:"50px"
        }}
      >
        <Box
          component={"img"}
          src={ComapnySVG}
          sx={{
            width: { xs: '250px', md: '520px', lg: '680px' },
            height: 'auto', objectFit: 'cover'
          }}

        />
        <Box sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          width:"99%"
        }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              mb:"30px"
            }}
          >
            <Typography variant='h1' >{t('SIGNUP_HEADER')}</Typography>
{/*             <Typography variant='h3'>{t('SIGNUP_HEADER2')}</Typography>
 */}          </Box>
          <Box component='form' className='tab-content-form'>
            <TextField
              fullWidth
              variant='standard'
              id='company_name'
              name='company_name'
              autoFocus
              label={t('SIGNUP_BUSINESS_NAME')}
              helperText={t('SIGNUP_BUSINESS_NAME_HELPTEXT')}
              autoComplete='off'
              autoCorrect='off'
              value={company_name}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <TextField
              variant='standard'
              fullWidth
              name='address'
              label={t('SIGNUP_ADDRESS')}
              helperText={t('SIGNUP_ADDRESS_HELPTEXT')}
              id='address'
              type='address'
              autoComplete='off'
              autoCorrect='off'
              value={company_address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <TextField
              variant='standard'
              fullWidth
              name='email'
              label={t('SIGNUP_EMAIL')}
              helperText={t('SIGNUP_EMAIL_HELPTEXT')}
              id='email'
              dir="ltr"
              sx={{ textAlign: 'left' }}
              type='email'
              autoComplete='off'
              autoCorrect='off'
              value={company_email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant='standard'
              fullWidth
              name='phone'
              label={t('SIGNUP_PHONE')}
              helperText={t('SIGNUP_PHONE_HELPTEXT')}
              id='phone'
              dir="ltr"
              sx={{ textAlign: 'left' }}
              autoComplete='off'
              autoCorrect='off'
              value={company_phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <NoFlipBox>
              <ButtonCustom
                color='primary'
                type='submit'
                variant='contained'
                onClick={SignupHandler}
              >
                {t('SIGNUP_PHONE_BUTTON')}
              </ButtonCustom>
            </NoFlipBox>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SignUP;
